import { Middleware } from "@nuxt/types";
const redirects: Middleware = ({ route, redirect }) => {
  if (route.path === "/services/tipps%26tricks") {
    redirect("/services/tipps-tricks");
    return;
  }

  if (route.path.startsWith("/partner/")) {
    const newPath = route.path.replace("/partner/", "/heizoelhaendler/");
    redirect(301, newPath);
  }
};

export default redirects;
