type AnalyticsEvent = {
  category: string,
  label: string,
  action: string,
};

export default (ctx, data: AnalyticsEvent) => {
  const event = {event: "track", ...data};
  // (ctx as any).$gtm.push(event);
}
