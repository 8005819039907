import { APIErrorResponseMessages } from "@/operations/messages";

export const getMessage = (error: any, ctx: any, redirect = true) => {
  let statusCode: number;
  let message: any;

  if (Array.isArray(error)) {
    message = error.map(
      (item: any) => APIErrorResponseMessages[item.message] || item.detail,
    );
    statusCode = error[0].status || 404;
  } else {
    message =
      APIErrorResponseMessages[error.message || error.response.data.message];
    statusCode = error.response.status || 404;
  }

  // direkt matches
  if (statusCode === 404) {
    message = message || APIErrorResponseMessages.resourceNotFound;
  } else if (statusCode >= 400 && statusCode < 500) {
    message = message || APIErrorResponseMessages.accessDenied;
  } else if (statusCode >= 500) {
    message = APIErrorResponseMessages.internal;
  }

  if (statusCode === 401 && redirect) {
    ctx.dispatch("user/logout", true, { root: true });
    message = APIErrorResponseMessages.unauthorized;
  }
  return message;
};
