// Replace external links from markdown with plain text

import MarkdownIt from "markdown-it";

const md = new MarkdownIt();

md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
  const token = tokens[idx];
  const hrefIndex = token.attrIndex("href");
  const href = token.attrs[hrefIndex][1];

  if (href.startsWith("http") || href.startsWith("//")) {
    // Replace external links with plain text
    return `<span>${tokens[idx + 1].content}</span>`;
  } else {
    return self.renderToken(tokens, idx, options);
  }
};

export default ({ app }, inject) => {
  inject("md", md);
};
