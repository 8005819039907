/**
 * Components which are registered within the global.js file can be used globaly without having to import these
 *
 * CAUTION: only register atoms, which can and will be used all over the application!
 *       more information on why this is can be found here: https://vueschool.io/lessons/global-vs-local-components
 */

import dayjs from "dayjs";
import { decode } from "html-entities";
import Vue from "vue";
import EmbeddedVideo from "~/components/atoms/embedding/EmbeddedVideo.vue";
import EsyImg from "~/components/atoms/embedding/EsyImg.vue";
import EsyPicture from "~/components/atoms/embedding/EsyPicture.vue";
import Icon from "~/components/atoms/embedding/Icon.vue";
import Checkbox from "~/components/atoms/form/Checkbox.vue";
import EsyButton from "~/components/atoms/form/EsyButton.vue";
import EsyInput from "~/components/atoms/form/EsyInput.vue";
import EsySelect from "~/components/atoms/form/EsySelect.vue";
import EsyTextarea from "~/components/atoms/form/EsyTextarea.vue";
import RadioButton from "~/components/atoms/form/RadioButton.vue";
import EsyArticle from "~/components/atoms/sectioning/EsyArticle.vue";
import EsySection from "~/components/atoms/sectioning/EsySection.vue";
import GridContainer from "~/components/atoms/sectioning/GridContainer.vue";
import OrderedList from "~/components/atoms/sectioning/OrderedList.vue";
import UnorderedList from "~/components/atoms/sectioning/UnorderedList.vue";
import TableData from "~/components/atoms/table/TableData.vue";
import TableHeader from "~/components/atoms/table/TableHeader.vue";
import EsyFigcaption from "~/components/atoms/text/EsyFigcaption.vue";
import EsyH1 from "~/components/atoms/text/EsyH1.vue";
import EsyH2 from "~/components/atoms/text/EsyH2.vue";
import EsyH3 from "~/components/atoms/text/EsyH3.vue";
import EsyHR from "@/components/atoms/text/EsyHR.vue";
import EsyParagraph from "~/components/atoms/text/EsyParagraph.vue";
import ListItem from "~/components/atoms/text/ListItem.vue";

const components = {
    EsySection,
    GridContainer,
    EsyArticle,
    OrderedList,
    UnorderedList,
    TableData,
    TableHeader,
    EsyH1,
    EsyH2,
    EsyH3,
    EsyHR,
    EsyParagraph,
    ListItem,
    EsyFigcaption,
    Icon,
    EsyImg,
    EsyPicture,
    EmbeddedVideo,
    EsyButton,
    Checkbox,
    EsyInput,
    RadioButton,
    EsySelect,
    EsyTextarea,
};

Object.entries(components).forEach(([name, component]) => {
    Vue.component(name, component);
});

export default async(context) => {
    if (!process.server)
        await Promise.all([context.store.dispatch("user/nuxtInit")]);
};

Vue.filter("formatDate", (a) => {
    return dayjs(a).format("DD.MM.YYYY");
});

Vue.filter("decode", (a) => {
    return decode(a);
});